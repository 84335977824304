<template>
  <v-text-field ref="input" v-bind="$attrs" v-on="$listeners" :value="inpValue"></v-text-field>
</template>

<script>
export default {
    props: ['value'],
    computed: {
       inpValue () {
           if ( typeof this.value == 'string') return this.value
           return this.value.formatted_address
       }
    },
    mounted () {
        const { input } = this.$refs
        const autocomplete = new window.google.maps.places.Autocomplete(input.$el.querySelector('input'))
        autocomplete.setComponentRestrictions({
            country: ["il"],
        });
        autocomplete.addListener("place_changed", () => {
            const {formatted_address , geometry} = autocomplete.getPlace();
            this.$emit('input', {
                formatted_address,
                location: geometry.location.toJSON()
            })
            console.log({
                formatted_address,
                location: geometry.location.toJSON()
            });

        })
    }
}
</script>

<style>

</style>